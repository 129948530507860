// ==========================================================================
//
//	Panels
//
// ==========================================================================

// --------------------------------------------------------------------------
//   Generic Panel
// --------------------------------------------------------------------------

.panel {
	background-color: $gray;
	box-shadow: 0 4px 0 $purple;
	padding: $base-padding;
}

h2.panel-heading {
	font-family: $gotham;
	text-transform: uppercase;
	font-style: normal;
	font-weight: 700;
	display: inline-block;
	width: 80%;
	color: $orange;

	@include media($mobile-down) {
		width: 100%;
	}
}

.panel-body {
	color: $purple;
}

.panel-button {

}

// --------------------------------------------------------------------------
//   Panel Right
// --------------------------------------------------------------------------

.panel-half {
	@include span-columns(6);

	@include media($tablet-down) {
		max-width: 570px;
	}

	@include media($mobile-down) {
		@include span-columns(12);
	}
}
