// ==========================================================================
//
//	Banner Section
//
// ==========================================================================

.section-banner, .section-banner-bar {
	width: 100%;
	text-align: center;
	padding: 180px 0;
	background-size: cover;
	background-repeat: no-repeat;
}

.section-banner-heading {
	color: $white;
	text-transform: uppercase;
	font-family: $gotham;
	font-style: normal;
	font-weight: 700;
	margin-bottom: 10px;
}

.section-banner-subheading {
	color: $white;
	font-family: $gotham;
	font-style: normal;
	font-weight: 500;
	margin: 0;
}

// --------------------------------------------------------------------------
//   Banner Bar Section
// --------------------------------------------------------------------------

.section-banner-bar {
	background-color: $purple;
	padding: $section-padding-lg;
}

.banner-bar-heading {
	color: $white;
	text-transform: initial;
	font-family: $gotham;
	font-style: normal;
	font-weight: 300;
	margin-bottom: 10px;
	line-height: 2;
}

// --------------------------------------------------------------------------
//   Variations
// --------------------------------------------------------------------------

.section-banner--gold {
	background-color: $gold;
}

.section-banner--purple {
	background-color: $purple;
}

.section-banner--orange {
	background-color: $orange;
}

.section-banner--navy {
	background-color: $navy;
}
