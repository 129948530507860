// ==========================================================================
//
//	Footer
//
// ==========================================================================

// --------------------------------------------------------------------------
//   Footer Main Section
// --------------------------------------------------------------------------

footer {
  background-color: $navy;
  padding: $section-padding;
  min-height: 400px;

  @include media($mobile-down) {
    text-align: center;
  }
}

.footer-content {
  color: $white;
}

.footer-contact {
  float: left;
  margin-bottom: 75px;

  p {
    margin-bottom: 5px;
  }

  @include media($mobile-down) {
    float: none;
    width: 100%;
    margin-bottom: 40px;
  }
}

.footer-contact--email,
.footer-contact--phone {

  a {
    color: $white;

    &:hover {
      color: $orange;
    }
  }
}

.footer-contact--heading {
  font-family: $gotham;
  font-style: normal;
  font-weight: 700;
}

.footer-logo {
  float: right;

  @include media($mobile-down) {
    float: none;
    width: 100%;
    margin-bottom: 40px;
  }
}

// --------------------------------------------------------------------------
//   Footer Social Icons
// --------------------------------------------------------------------------

.footer-social {
  float: none;
  clear: both;

  @include media($mobile-down) {
    float: none;
    width: 100%;
    margin-bottom: 40px;
  }
}

.footer-social-icon {
  display: inline-block;
  background: transparent;
  height: 46px;
  width: 46px;
  margin: 0 5px;
  position: relative;
  transition: $transition;

  &.footer-social-icon--facebook {

    &:hover, &:focus {
      background: #3b5998;

      .footer-social-svg {
        border-color: #3b5998;
      }
    }

  }

  &.footer-social-icon--email {

    &:hover, &:focus {
      background: $orange;

      .footer-social-svg {
        border-color: $orange;
      }
    }

  }
}

.footer-social-svg {
  height: 46px;
  width: 46px;
  transition: $transition;
  border: 1px solid $white;
  padding: 10px;
}

// --------------------------------------------------------------------------
//   Footer Bottom Section
// --------------------------------------------------------------------------

.footer-bottom {
  position: relative;
  height: auto;
  background-color: $purple;
  color: $white;
  padding-top: 12px;
  padding-bottom: 12px;

  @include media($mobile-down) {
    text-align: center;
  }
}

.footer-bottom-copyright {

}

a.footer-bottom-privacy {
  font-weight: bold;
  color: $white;

  @include media($mobile-down) {
    width: 100%;
    display: block
  }

  &:hover,
  &:focus,
  &:active {
    color: $navy;
  }
}
