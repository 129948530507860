// ==========================================================================
//
//	Border box component
//
// ==========================================================================

.cta-panel {
	@include span-columns(12);
	margin-right: 0;
	position: relative;
	background-color: $white;
	border-bottom: $border-bottom;
	padding: $base-padding;
	margin-bottom: $base-margin * 2;

		@include media($desktop-up) {
			padding: 40px 80px;
		}
}

// --------------------------------------------------------------------------
//   Left CTA Panel
// --------------------------------------------------------------------------

.cta-panel-left {
	float: left;
	display: block;
	margin-right: 0;
	width: 50%;

	@include media($tablet-down) {
		@include span-columns(12);
		text-align: center;
    margin-bottom: 20px;
	}
}

.cta-panel-icon {
	float:left;
	margin-right: 20px;

	@include media($tablet-down) {
		float: none;
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.cta-panel-heading {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $gotham;
		font-style: normal;
		font-weight: 700;
		text-transform: uppercase;
		@include font-size(36px);
		margin-bottom: 0.2rem;
	}
}

.cta-panel-subheading {

	h6 {
		font-family: $gotham;
		text-transform: inherit;
		color: $black;
		font-style: normal;
		font-weight: 700;
		@include font-size(20px);
		margin-bottom: 1rem;
	}
}

.cta-panel-subheading--light {
	font-family: $gotham;
	font-style: normal;
	font-weight: 300;
	text-transform: inherit;
	@include font-size(16px);
	color: $dark-purple;
	text-transform: uppercase;
	margin-bottom: 0.4rem;
}

.cta-panel-body {
  margin-bottom: 20px;

	@include media($tablet-up) {
		min-height: 107px;
		max-height: 107px;
    overflow: hidden;
    padding-right: $base-padding;
	}
}

.cta-panel-button {
	display: inline-block;

	&:hover {
		a {
			color: $white;
		}
	}
	@include media($mobile-down) {
		margin-bottom: 20px;
	}
}

// --------------------------------------------------------------------------
//   Right CTA Panel
// --------------------------------------------------------------------------

.cta-panel-right {
	@include span-columns(6);

	@include media($tablet-down) {
		@include span-columns(12);
		text-align: center;

	}
}

.cta-panel-image {
	text-align: left;

	@include media($tablet-down) {
		text-align: center;
	}
}

// --------------------------------------------------------------------------
//   Color variations
// --------------------------------------------------------------------------

.cta-panel--news {
	background-color: $gray;
}

.cta-panel--yellow {

	.cta-panel-heading {


			h1 {
				color: $yellow;
			}

			.cta-panel-heading-title {
				color: $yellow;
			}
	}

	.cta-panel-subheading {

	}

}

.cta-panel--blue {

	.cta-panel-heading {

		h1 {
			color: $blue;
		}

		.cta-panel-heading-title {
			color: $blue;
		}

	}

	.cta-panel-subheading {

	}

}
