// ==========================================================================
//
//	Team card
//
// ==========================================================================

.card-team {
	@include span-columns(4);
	@include omega(3n);
	padding: 10px;
	margin-bottom: $base-margin;
	background: none;
	max-width: 400px;

	&:hover {
		.card-team-thumb {
			-webkit-filter: brightness(70%);
		}
	}

	@include media($tablet-down) {
		@include span-columns(6);
		@include omega(2n);
		@include omega-reset(3n);
	}

	@include media($mobile-down) {
		@include span-columns(12);
		text-align: center;
		float: none;
		margin: 0 auto !important;
	}
}

.card-team-thumb {
	width: 100%;
	height: 300px;
	transition: $transition;
	background-size: cover;
	background-position: center;
	background-color: $purple;
	background-repeat: no-repeat;
}

.card-team-body {
	padding: 20px;
	background-color: $white;
}

.card-team-heading {
	color: $navy;
	@include font-size(16px);
	font-weight: 800;
	font-style: normal;
	margin-bottom: 0;
	text-transform: uppercase;

}

.card-team-subheading {
	color: $navy;
	margin-bottom: 0;
}
