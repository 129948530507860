// ==========================================================================
//
//	Border box component
//
// ==========================================================================

.border-box {
	position: relative;
	display: block;
	vertical-align: middle;
	width: 350px;
	height: 350px;
	background: $purple;
	color: $white;
	border: 40px solid $white;
}

.border-box-inner {
	text-align: center;
	line-height: 150px;
}
