// ==========================================================================
//
// Style
// This file imports libraries, defines variables and sets defaults
//
// ==========================================================================

// --------------------------------------------------------------------------
// Wordpress Theme Header
// --------------------------------------------------------------------------

/*!
Theme Name: McCusker
Version: 5.0.0
Author: github.com/ryanberry
*/


// --------------------------------------------------------------------------
// Libraries
// --------------------------------------------------------------------------

@import 'base/fonts';
@import 'bourbon';
@import 'utilities/_clearing.scss';
@import 'utilities/_hiding.scss';
@import 'utilities/_mixins.scss';
@import 'utilities/_utilities.scss';

@import 'base/reset';
@import 'neat';
@import 'neat-helpers';

// --------------------------------------------------------------------------
// Configuration
// --------------------------------------------------------------------------



// --------------------------------------------------------------------------
// Variables
// --------------------------------------------------------------------------

// ----- Colours ----- //

$black: 				#00192b;
$white:					#ffffff;
$white-text:		#fff9f4;
$purple:				#63459b;
$dark-purple: 	#341b46;
$orange:				#f26336;
$gray: 					#eeeeee;
$navy:					#00192b;
$yellow:				#e1e448;
$gold:					#feb825;
$blue:					#006fba;
$dark-gray:     #929292;


// ----- Typography ----- //

$base-body-color: 		$black;
$base-heading-color:	$purple;

$base-link-color: 		$purple;
$base-link-hover: 		lighten($base-link-color, 10%);

$base-border-color: 	$white;
$base-border-radius:	0;

$border-bottom: 5px solid $navy;

$paragraph: 500px;

$base-line-height: 		1.375rem;
$base-font-size: 			1rem;

$montserrat-regular: 'montserratregular', Helvetica, Arial, sans-serif;
$montserrat-bold: 'montserratbold', Helvetica, Arial, sans-serif;
$montserrat-light: 'montserratlight', Helvetica, Arial, sans-serif;

$gotham: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;

/*

// Gotham light //
font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
font-style: normal;
font-weight: 300;

// Gotham Book //
font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
font-style: normal;
font-weight: 400;

// Gotham Medium //
font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
font-style: normal;
font-weight: 500;

// Gotham Bold //
font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
font-style: normal;
font-weight: 700;

// Gotham Black //
font-family: "Gotham SSm A", "Gotham SSm B", Helvetica, Arial, sans-serif;
font-style: normal;
font-weight: 800;

*/

// --------------------------------------------------------------------------
//   Paddings/Margins
// --------------------------------------------------------------------------

$input-padding: 15px 14px;
$section-padding: 75px 0;
$section-padding-sm: 37.5px 0;
$section-padding-lg: 115px 0;
$section-small-padding: 30px 0;
$section-large-padding: 100px 0;
$section-margin: 60px;
$base-margin: 1.375rem;
$base-padding: 40px;

// --------------------------------------------------------------------------
//   Transitions and animations
// --------------------------------------------------------------------------

$transition: all 0.3s ease-in-out;
$bg-transition: background 0.3s ease-in-out;

// --------------------------------------------------------------------------
// Grid
// --------------------------------------------------------------------------

// ----- Default grid settings ----- //

$max-width: 		1170px;
$grid-columns:	12;
$gap: 					20px;

// ----- Define your breakpoints ----- //

$large-desktop:	1280px;
$desktop:				880px;
$tablet:				680px;
$mobile:				380px;

$desktop-down:				new-breakpoint(screen and max-width ($desktop + 440px) 12);
$tablet-down:					new-breakpoint(screen and max-width ($tablet + 440px) 12);
$mobile-down:					new-breakpoint(screen and max-width ($mobile + 440px) 12);

$desktop-up:					new-breakpoint(screen and min-width ($desktop + 441px) 12);
$tablet-up:						new-breakpoint(screen and min-width ($tablet + 441px) 12);
$mobile-up:						new-breakpoint(screen and min-width ($mobile + 441px) 12);


// --------------------------------------------------------------------------
// Initialise media query order
// --------------------------------------------------------------------------

@include media($desktop-down) { /* */ }
@include media($tablet-down) { /* */ }
@include media($mobile-down) { /* */ }

@include media($mobile-up) { /* */ }
@include media($tablet-up) { /* */ }
@include media($desktop-up) { /* */ }


// --------------------------------------------------------------------------
//   Utilities
// --------------------------------------------------------------------------

@import 'utilities/mixins';
@import 'utilities/clearing';
@import 'utilities/hiding';

// --------------------------------------------------------------------------
//   Base
// --------------------------------------------------------------------------

@import 'base/forms';
@import 'base/images';
@import 'base/print';
@import 'base/reset';
@import 'base/typography';

// --------------------------------------------------------------------------
//   Layout
// --------------------------------------------------------------------------

@import 'layout/layout';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/sections';
@import 'layout/statistics';

// --------------------------------------------------------------------------
//   Components
// --------------------------------------------------------------------------

@import 'components/buttons';
@import 'components/inputs';
@import 'components/border-box';
@import 'components/card-team';
@import 'components/card-related';

// --------------------------------------------------------------------------
//   Modules
// --------------------------------------------------------------------------

@import 'modules/navigation';
@import 'modules/slider';
@import 'modules/cta-panel';
@import 'modules/panel';
@import 'modules/breadcrumbs';
@import 'modules/banner';
@import 'modules/related-section';
@import 'modules/side-image';
@import 'modules/modal';

// --------------------------------------------------------------------------
//   Pages
// --------------------------------------------------------------------------

@import 'pages/standard';
@import 'pages/home';
@import 'pages/about';
@import 'pages/donate';
@import 'pages/contact';

// --------------------------------------------------------------------------
//   "Shame, Shame, Shame"
// --------------------------------------------------------------------------

@import 'base/shame';
