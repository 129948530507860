// ==========================================================================
//
//	Buttons
//
// ==========================================================================

.button,
.gform_page_footer .gform_next_button,
.gform_page_footer .gform_previous_button,
.gform_button,
.search-section-404 .search-submit {
	font-family: $gotham;
	display: inline-block;
	padding: 15px 40px;
	color: $purple;
	background-color: $white;
	background-clip: padding-box;
	text-decoration: none;
	max-width: 100%;
	word-wrap: break-word;
	white-space: normal;
	@include font-size(15px);
	font-style: normal;
	font-weight: 300;
	border-radius: 0;
	border: 1px solid $purple;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
	transition: $transition;

	&:hover {
		background-color: $navy;
		color: $white;
		border-color: $navy;
	}
}

.button-orange {
	color: $white;
	background-color: $orange;
	border: 1px solid $orange;

	&:hover {
		background-color: $navy;
		border: 1px solid $navy;
	}

		a {
			color: $white;
		}
}
