// ==========================================================================
//
//  Web Fonts
//
// ==========================================================================

@font-face {
    font-family: 'montserratblack';
    src: url('fonts/montserrat/montserrat-black-webfont.eot');
    src: url('fonts/montserrat/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-black-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-black-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-black-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratbold';
    src: url('fonts/montserrat/montserrat-bold-webfont.eot');
    src: url('fonts/montserrat/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-bold-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-bold-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratextra_bold';
    src: url('fonts/montserrat/montserrat-extrabold-webfont.eot');
    src: url('fonts/montserrat/montserrat-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-extrabold-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-extrabold-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-extrabold-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-extrabold-webfont.svg#montserratextra_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserrathairline';
    src: url('fonts/montserrat/montserrat-hairline-webfont.eot');
    src: url('fonts/montserrat/montserrat-hairline-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-hairline-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-hairline-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-hairline-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-hairline-webfont.svg#montserrathairline') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratlight';
    src: url('fonts/montserrat/montserrat-light-webfont.eot');
    src: url('fonts/montserrat/montserrat-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-light-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-light-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-light-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-light-webfont.svg#montserratlight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratregular';
    src: url('fonts/montserrat/montserrat-regular-webfont.eot');
    src: url('fonts/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratsemi_bold';
    src: url('fonts/montserrat/montserrat-semibold-webfont.eot');
    src: url('fonts/montserrat/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-semibold-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-semibold-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-semibold-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-semibold-webfont.svg#montserratsemi_bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'montserratultra_light';
    src: url('fonts/montserrat/montserrat-ultralight-webfont.eot');
    src: url('fonts/montserrat/montserrat-ultralight-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/montserrat/montserrat-ultralight-webfont.woff2') format('woff2'),
         url('fonts/montserrat/montserrat-ultralight-webfont.woff') format('woff'),
         url('fonts/montserrat/montserrat-ultralight-webfont.ttf') format('truetype'),
         url('fonts/montserrat/montserrat-ultralight-webfont.svg#montserratultra_light') format('svg');
    font-weight: normal;
    font-style: normal;
}
