// ==========================================================================
//
//  Print
//    Printable Stylesheet
//
// ==========================================================================

@media print {

	* { background: transparent !important; color: #444 !important; text-shadow: none; }

	nav,
	#main-nav,
	#searchform,
	#contact-details,
	#sidebar {
		display: none !important;
	}

	#content { width: 100%; }

	a, a:visited { color: #444 !important; text-decoration: underline; }

	a:after { content: " (" attr(href) ")"; }

	abbr:after { content: " (" attr(title) ")"; }

	.ir a:after { content: ""; }	/* Don't show links for images */

	pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }

	img { page-break-inside: avoid; }

	@page { margin: 0.5cm; }

	p, h2, h3 { orphans: 3; widows: 3; }

	h2, h3{ page-break-after: avoid; }

}
