// ==========================================================================
//
//  Input fields
//
// ==========================================================================

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], textarea, textarea, select {
	width: 100%;
	max-width: 100%;
	border: 0px;
	padding: 10px;
	@include font-size(16px);
	line-height: 1.4rem;
	text-align: left;
	background-color: $white;
	color: $navy;
}

// --------------------------------------------------------------------------
//   Text Area
// --------------------------------------------------------------------------

textarea {
	width: 100%;
}

// --------------------------------------------------------------------------
//   Select Box
// --------------------------------------------------------------------------

select {
  text-indent: 0.01px;
  text-overflow: '';
  border-radius: 0;
  height: 43px;
  width: 100%;
}

// Hide dropdown arrow on IE10+
select::-ms-expand {
	display: none;
}
