// ==========================================================================
//
//	Slider/Hero
//
// ==========================================================================

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
  position: absolute;
  z-index: 999;
  text-indent: -9999px;
  transform: translateY(-50%);
  top: 50%;
  display: block;
  background: none;
  -webkit-appearance: none;
  border: none;
  height: 80px;
  width: 60px;
  box-shadow: none;
  transition: all 0.4s ease;

  &:focus {
    outline: none;
  }

  &.slick-prev {
    left: 20px;
    background: url(images/arrow-left.png);
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      left: 15px;
    }
  }

  &.slick-next {
    right: 20px;
    background: url(images/arrow-right.png);
    background-size: 23px;
    background-repeat: no-repeat;
    background-position: center;

    &:hover {
      right: 15px;
    }
  }

}

.hero {
  $base-border-radius: 3px !default;
  $action-color: #477DCA !default;
  $large-screen: em(860) !default;
  $hero-background-top: #7F99BE;
  $hero-background-bottom: #20392B;
  $hero-color: white;
  $gradient-angle: 10deg;
  $hero-image: 'images/home-slide-1.jpg';
	background-color: $gray;

  .hero-slider {
    @include outer-container;
    @include clearfix;
    margin-bottom: -40px;
    border: solid $white;
    border-width: 0 40px 40px;
    padding: 0;
    text-align: center;

    @include media($mobile-down) {
      border-width: 10px;
      min-height: 600px;
      margin-bottom: 0;
    }
  }

  .slick-dots {
    position: relative;
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-top: 25px;

      li {
        display: inline-block;
        float: none;
        list-style: none;


      &.slick-active,
      &:hover,
      &:focus,
      &:active {
        button {
          background-color: $navy;
        }
      }

      button {
        display: block;
        border: 1px solid $navy;
        background-color: $white;
        width: 18px;
        height: 18px;
        max-width: 18px;
        max-height: 18px;
        overglow: hidden;
        margin: 0 5px;
        border-radius: 18px;
        text-indent: 9999px;
        line-height: 0;
        -webkit-transition: $bg-transition;
      }


      }
  }

  .hero-inner, .hero-slider .slick-slide.hero-slider-slide {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 580px;
    width: 100%;
    margin: auto;
    z-index: 9;
  }

  .hero-content {
    position: absolute;
    bottom:  14%;
    right: 10%;
    width: 100%;
    height: 100%;
    background: $white;
    text-align: center;
    padding: 30px 40px;
    max-width: 450px;
    height: auto;

    @include media($mobile-down) {
      top: auto;
      bottom: 10px;
      padding: 10px;
      right: 0;
      left: 0;
      width: 95%;
      margin: 0 auto;
    }

    .hero-title {
      color: $purple;
      @include font-size(16px);

      @include media($large-screen) {
        @include font-size(18px);
        margin-bottom: 25px;
      }
    }
  }
}
