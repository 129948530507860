// ==========================================================================
//
//	Breadcrumbs
//
// ==========================================================================

.section-breadcrumbs {
  width: 100%;
  background: $white;
  margin: 0;
  padding: 7px 0;
  border-top: 1px solid $purple;
  border-bottom: 1px solid $purple;
}

.breadcrumbs-item {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
  color: darken($gray, 70%);
  @include font-size(14px);

  &:not(:last-child):after {
    content: ">";
    position: relative;
    margin-left: 10px;
    color: $purple;
  }

}

.breadcrumbs-link {
  @include font-size(14px);

  &:hover,
  &:active,
  &:focus {
    color: $orange;
    text-decoration: underline;
  }
}

.breadcrumbs-divider {
  padding-left: 5px;
  padding-right: 5px;
}
