// ==========================================================================
//
//	Donate Template Styles
//
// ==========================================================================

// --------------------------------------------------------------------------
//   Donate Form General Styles
// --------------------------------------------------------------------------

.section-donate-form {
	background-color: $gray;
	padding: $section-padding;
	color: $navy;
	text-align: center;

	@include media($tablet-up) {
		border-top: 5px solid $orange;
	}
}

.donate-form-content {

}

#gform_fields_1 {

	li {

		margin-bottom: 10px;

		&.donate-form-break {
			border-bottom: 6px solid $dark-gray;
			margin-bottom: 50px;
			display: block;
		}

	}



	.donation-form-address {
		margin-bottom: 0;
	}

	.donate-form-phone {
  	margin-top: -20px;

		@include media($mobile-down) {
			input {
				width: 100%;
			}
		}
	}

}

.donate-form-break {
	border-bottom: 6px solid $dark-gray;
	margin-bottom: 50px;
	display: block;
}

.donate-form-title {

	.gsection_title {
		color: $navy;
		@include font-size(30px);
		text-transform: uppercase;
		margin-bottom: 30px;
		font-family: $gotham;
		font-style: normal;
		font-weight: 300;
	}

}

.donate-form-wrap {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $white;
	}

	.ginput_complex {

		input {
	    width: 100%;
	  }

	}

	table {
		text-align: center;
		border-collapse: separate !important;
		border-spacing: 10px !important;
		background-color: $gray;
		max-width: $paragraph;
		margin: 0 auto;
		text-align: left;
		margin-bottom: $base-margin;

		> tr, tr {
			background-color: $gray;
			border-collapse: separate;
			border-spacing: 0 2px;

			> td, td {
				font-family: $gotham;
				font-style: normal;
				font-weight: 700;
				font-size: 16px !important;

				&[colspan="2"] {
					color: $purple;
					font-size: 22px !important;
					font-weight: 300;
				}

					strong {
						font-family: $gotham;
						font-style: normal;
						font-weight: 500;
						font-size: 16px !important;
					}
					font {
						font-family: $gotham;
						font-style: normal;
						font-weight: 300;
						font-weight: normal;
						font-size: 14px !important;
					}
			}

			> ul, ul {

				> li, li {
					font-family: $gotham;
					font-style: normal;
					font-weight: 300;
					font-weight: normal;
					@include font-size(14px);
				}

			}

		}

	}

	.label-left {

		label {
			float: left;
		}

	}
}

.left-half {
	width: 48%;
	margin-right: 4%;
	vertical-align: top;
	clear: none;
	display: inline-block;

	@include media($tablet-down) {
		width: 100%;
		margin-right: 0;
	}
}

.right-half {
	width: 48%;
	clear: none;
	display: inline-block;

	@include media($tablet-down) {
		width: 100%;
	}
}

.gform_page_footer {

	.gform_previous_button {
		background-color: $purple;
		color: $white;
	}

}

.gf_progressbar_title {
  border: 1px solid;
  padding: 20px;
  width: auto;
  display: inline-block;
  font-family: $gotham;
	font-style: normal;
	font-weight: 300;
}

.ginput_container_email {

	label {
		float: left;
	}

}

.donate-form-review {
	max-width: 700px;
  margin: 0 auto;
  border: 1px solid $dark-gray;
  padding: 30px;
}

.donate-form-review-details {
	text-align: center;
	line-height: 18px;
}

.donate-form-details-title {
	padding-left: 30px;
	@include font-size(13px);
  display: block;
  text-align: left;
  margin-bottom: 0;
  line-height: 14px;
	color: $navy;
	font-family: $gotham;
	font-style: normal;
	font-weight: 300;
	text-transform: uppercase;
	-webkit-font-smoothing: antialiased;
}

.donate-form-checkbox {

	.validation_message {
		text-align: left;
	}

}

// Phone

.donate-form-phone {
	text-align: left;
}

// --------------------------------------------------------------------------
//   Donate Form Pagination
// --------------------------------------------------------------------------

.gf_page_steps {
	margin-bottom: 60px;

	@include media($tablet-up) {
		position: absolute;
		top: -162px;
		width: 100%;
		border-bottom: 5px solid $orange;
	}

	&.page-steps-success {
		@include media($tablet-up) {
			top: -175px;
		}
	}
}

.gf_step_number {
	display: none;
	visibility: hidden;
}

.gf_step {
	padding: 20px 10px;
	border: 1px solid $white;
	border-color: $orange $orange $white $orange;
	color: $orange;
	display: inline-block;
	@include font-size(18px);
	clear: none;
	width: 31.33%;
	margin-right: 2.5%;
	background-color: $white;

	&.gf_step_last {
		margin-right: 0;
	}

	&.gf_step_success {
		width: 62.6%;
	}

	@include media($tablet-down) {
		@include font-size(16px);
		width: 100%;
		padding: 10px;
		margin-bottom: 10px;
	}


	&.gf_step_active {
		background-color: $orange;
		color: $white;
		font-family: $gotham;
		font-style: normal;
		font-weight: 700;
		border: 1px solid $orange;
	}

	&.gf_step_completed {
		background-color: $purple;
		color: $white;
		border: 1px solid $purple;
	}

	&.gf_step_complete {
		@include media($desktop-up) {
			position: absolute;
			top: -170px;
			left: 0;
			right: 0;
			float: none;
			text-align: center;
			margin-left: auto;
			margin-right: auto;
		}
	}

}

// --------------------------------------------------------------------------
//   Email Field
// --------------------------------------------------------------------------

.donate-form-email {
	margin-bottom: 60px;
	.ginput_complex {

		> .ginput_left {
			display: block;
			float: left;
			width: 48%;
			margin-right: 4%;

			@include media($mobile-down) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 37.5px;
			}
		}

		> .ginput_right {
			display: block;
			float: right;
			width: 48%;
			margin-right: 0;

			@include media($mobile-down) {
				width: 100%;
				margin-right: 0;
			}
		}

	}

}

.gf_clear.gf_clear_complex {
	margin-bottom: 20px;
	clear: both;
}

// --------------------------------------------------------------------------
//   Jumbo Radio Boxes
// --------------------------------------------------------------------------

.donate-form-radio-jumbo {

	&.radio-jumbo-half {

		.gfield_radio {

			li {
				width: 49.5%;
				margin-right: 1%;

				&:last-child {
					margin-right: 0;
				}
			}

		}

	}

	> label {
		display: none;
	}

		.gfield_radio {
			margin-top: 20px;
			margin-bottom: 20px;

			li {
			  display: inline-block;
			  padding: 63px 10px;
			  width: 24.25%;
			  margin-right: 1%;
				height: 153px;
			  cursor: pointer;
			  position: relative;
			  margin-bottom: 10px;
			  color: $white;
			  background-color: $purple;

			  > label {
					@include font-size(18px);
					padding-left: 0;
					font-family: $gotham;
					font-style: normal;
					font-weight: 700;
					position: absolute;
					width: 100%;
					border: 30px solid $white;

					color: $white;
					vertical-align: middle;
					left: 0;
					line-height: 100px;
					top: 0;
					height: 100%;

						@include media($tablet-down) {
							@include font-size(16px);
						}
			  }

				&:hover {
					.inner {
					  transform: none;
					  opacity: .5;
					}
				}

				input[type="radio"] {
				  height: 1px;
				  width: 1px;
				  opacity: 0;
					position: relative;
					z-index: 99;

					&:checked + label {
				    opacity: 0.99999;
						z-index: 999;
						opacity: 1;
				    position: absolute;
				    width: 100%;
						border: 30px solid $white;
						background-color: $orange;
				    color: $white;
				    vertical-align: middle;
				    left: 0;
				    line-height: 100px;
				    top: 0;
				    height: 100%;
				  }

				}

				label:before {
				  display: none;
				}

				label:after {
				  display: none;
				}

				@include media($tablet-down) {
					width: 32.25%;
					margin-right: 1%;

					&:nth-last-child {
						margin-right: 0;
					}

				}

				@include media($mobile-down) {
					width: 100%;
					margin-right: 0;
					padding: 20px 10px;

					&:nth-last-child(3), &:nth-last-child(5) {
						margin-right: 0;
					}

					&:nth-last-child(2) {
						width: 100%;
						margin-right: 0;
					}

				  &:nth-last-child(1) {
						width: 100%;
						margin-right: 0;
				  }

				}

		}

	}

	&.radio-jumbo--amount {

		.gfield_radio {

			li {

			  &:nth-last-child(3) {
			  	margin-right: 0;
			  }

				&:nth-last-child(2) {
					width: 49.5%;
					margin-right: 1%;
				}

			  &:nth-last-child(1) {
					width: 49.5%;
					margin-right: 0;
			  }

			  @include media($tablet-down) {
			  	width: 49.5%;
			  	margin-right: 1%;

			  	&:nth-last-child(3), &:nth-last-child(5) {
			  		margin-right: 0;
			  	}

			  	&:nth-last-child(2) {
			  		width: 49.5%;
			  		margin-right: 1%;
			  	}

			    &:nth-last-child(1) {
			  		width: 49.5%;
			  		margin-right: 0;
			    }

			  }

				@include media($mobile-down) {
					width: 100%;
					margin-right: 0;

					&:nth-last-child(2) {
						width: 100%;
						margin-right: 0;
			  	}

			    &:nth-last-child(1) {
						width: 100%;
						margin-right: 0;
			    }
				}

			}

		}

	}

}

// --------------------------------------------------------------------------
// Donate Form Memoriam
// --------------------------------------------------------------------------

.donate-form-memoriam-amount,
.donate-form-memoriam-to,
.donate-form-memoriam-message,
.donate-form-memoriam-contact,
.donate-form-other-amount {
	text-align: left;
	margin: 0 auto;
	width: 48%;

		.ginput_container {

			input {
				width: 100%;
			}

			textarea {
				width: 100%;
			}
		}

	@include media($mobile-down) {
		width: 100%;
	}
}

// --------------------------------------------------------------------------
// Donate Form Footer
// --------------------------------------------------------------------------

.gform_page_footer {

	.gform_next_button {
		background-color: $purple;
		color: $white;
		border-color: $purple;
	}

}

#gform_submit_button_1 {
	background-color: $purple;
	color: $white;
	border-color: $purple;

	&:hover {
		background-color: $navy;
		border-color: $navy;
	}
}

.gform_page_footer .gform_previous_button {
  color: $purple;
  background-color: $white;
}
