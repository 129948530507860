// ==========================================================================
//
//	Related Card
//
// ==========================================================================

.card-related {
	width: 100%;
	display: block;
	padding: 50px 20px;
	padding-bottom: 46px;
	margin-bottom: 40px;
	background-color: $white;
	text-align: center;
	border-bottom: $border-bottom;
	transition: $transition;

	&:hover,
	&:focus,
	&:active {
		background-color: $navy;

		.card-related-title {
			color: $white;
			transform: translateY(4px);
		}
	}
}

.card-related-title {
	@include font-size(30px);
	display: block;
	font-family: $gotham;
	font-style: normal;
	font-weight: 500;
	transition: $transition;
	text-transform: uppercase;
	color: $navy;
}
