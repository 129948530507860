// ==========================================================================
//
//  Utilities
//    Helper functions and mixins
//
// ==========================================================================

// --------------------------------------------------------------------------
//   Hidden  Elements
// --------------------------------------------------------------------------

.ir,
.hide-text,
.text-replace {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  background-repeat: no-repeat;
}

.hide,
.hidden,
#wpstats,
.screen-reader-text  {
  display: none;
  visibility: hidden;
}

@mixin visuallyhidden {
  position: absolute!important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px,1px,1px,1px);
}

@mixin invisible { visibility: hidden }


// --------------------------------------------------------------------------
//   Clearing
// --------------------------------------------------------------------------

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

html.clearfix { *height: 1% }


// --------------------------------------------------------------------------
//   Wordpress Specific
// --------------------------------------------------------------------------

#tinymce { padding: 1em; }


// --------------------------------------------------------------------------
//   Opacity
// --------------------------------------------------------------------------

@mixin opacity($value){
	$IEValue: $value*100;
	opacity: $value;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
	filter: alpha(opacity=$IEValue);
}


// --------------------------------------------------------------------------
//   CSS Hyphens
// --------------------------------------------------------------------------

@mixin css-hyphens($val) {
	// Accepted values: [ none | manual | auto ]
	-webkit-hyphens: $val;	// Safari 5.1 thru 6, iOS 4.2 thru 6
	-moz-hyphens: $val;		// Firefox 16 thru 20
	-ms-hyphens: $val;		// IE10
	-o-hyphens: $val;		// PRESTO...haha LOL
	hyphens: $val;			// W3C standard
}


// --------------------------------------------------------------------------
//   Calculate Rem
// --------------------------------------------------------------------------

@function parseInt($n) { /* 2 */
  @return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
  $px : (); /* 3 */
  $rem: (); /* 3 */

  @each $value in $values { /* 4 */

    @if $value == 0 or $value == auto { /* 5 */
      $px : append($px , $value);
      $rem: append($rem, $value);
    }

    @else {
      $unit: unit($value);    /* 6 */
      $val: parseInt($value); /* 6 */

      @if $unit == "px" {  /* 7 */
        $px : append($px,  $value);
        $rem: append($rem, ($val / 10 + rem));
      }

      @if $unit == "rem" { /* 7 */
        $px : append($px,  ($val * 10 + px));
        $rem: append($rem, $value);
      }
    }
  }

  @if $px == $rem {     /* 8 */
    #{$property}: $px;  /* 9 */
  } @else {
    #{$property}: $px;  /* 9 */
    #{$property}: $rem; /* 9 */
  }
}
