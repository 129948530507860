// ==========================================================================
//
//  Forms
//		Form elements, plugin styling
//
// ==========================================================================


// --------------------------------------------------------------------------
//   Variables
// --------------------------------------------------------------------------

$form-element-border: 1px solid $base-border-color;


// --------------------------------------------------------------------------
//   Buttons
// --------------------------------------------------------------------------

@mixin main-button ($colour1: $base-link-color) {
	display: inline-block;
	padding: 0.5em 1em;
	background-color: $colour1;
	border: 1px solid $colour1;
	border-radius: $base-border-radius;
	outline: 0;
	zoom: 1;
	cursor: pointer;
	user-select: none;
	-webkit-user-drag: none;

	color: white;
	white-space: nowrap;
	vertical-align: baseline;
	text-decoration: none;
	text-align: center;
	line-height: normal;

	&:hover {
		outline: 0;
		background: $colour1 - #111;
		border-color: $colour1 - #111;
	}

	&:active, &.active {
		outline: 0;
		box-shadow: 0 0 0 1px rgba(0,0,0,.15) inset,0 0 6px rgba(0,0,0,.2) inset;
	}

	&.disabled, &[disabled] {
		background-image: none;
		@include opacity(0.4);
		cursor: not-allowed;
		box-shadow: none;
	}
}


// --------------------------------------------------------------------------
//   Generic form elements
// --------------------------------------------------------------------------

form {

	// ----- Remove default styling ----- //

	ul, ol, li {
		padding: 0;
		list-style: none;
	  margin-bottom: 30px;
	}

	label {
		font-family: $gotham;
		font-style: normal;
		font-weight: 300;
		@include font-size(16px);
		@include line-height(24px);
	}
}

// ----- Buttons ----- //

.button,
input[type="button"],
input[type="reset"] {
	@include main-button;
}

// ----- Input types ----- //

#{$all-text-inputs},
textarea,
select {
	max-width: 100%;
  border: $form-element-border;
  padding: 5px 10px;
  font-weight: normal;

  &.medium {
	  width: 48%;
  }
  &.large {
	  width: 100%;
  }
}

#{$all-text-inputs-hover},
textarea,
select {
  background: white;
}

#{$all-text-inputs-focus},
textarea,
select {
  background: white;
}

textarea.medium {
	height: 100px;
}

textarea.large {
	height: 150px;
}

input[type="checkbox"], input[type="radio"] {
	margin-right: 10px;
}

// --------------------------------------------------------------------------
//   Gravity form generic styles
// --------------------------------------------------------------------------

.gfield_description {

	&.validation_message {
	  color: #F26336;
		margin-bottom: 20px;
	}

}

.gform_body {

}

.validation_error {
	padding: 20px;
  border: 1px solid #f26336;
  color: #f26336;
  margin-bottom: 2.375rem;
  position: absolute;
  float: left;
  max-width: 700px;
  left: 0;
  right: 0;
  margin: 0 auto;
  line-height: 10px;
  top: -66px;
  float: none;
}

.gfield_required {
	display: none;
	opacity: 0;
}

.gfield_error {

	.ginput_container {

		input, textarea, select {
		  border: 1px solid $orange;
		}

		.address_line_2 {

			input {
				border: 1px solid $white;
			}

		}


	}

}

.gform_wrapper {


		label {
			display: inline-block;
			margin-bottom: 5px;
			font-family: $gotham;
			font-style: normal;
			font-weight: 300;
			@include font-size(18px);
			@include line-height(26px);
		}

}

.ginput_container_checkbox {
  text-align: left;
}

// Must add these classes to radio and checkboxes to give a margin below the label
.form-checkbox, .form-radio {

	> label {
		margin-bottom: 20px;
	}
}

.ginput_container_address {

	span {
		margin-bottom: 30px;
	}

	label {
		float: left;
	}

}


/*
 * Radio Boxes
 */

.gfield_radio,
.gfield_checkbox {

	li {
	  display: inline-block;
	  padding-right: 20px;
	  font-size: 18px;
	  @include line-height(26px);
	  cursor: pointer;
	  position: relative;

	  > label {
			padding-left: 32px;
			@include font-size(18px);
			@include line-height(22px);
	  }

		&:hover {
			.inner {
			  -webkit-transform: scale(0.5);
			  transform: scale(0.5);
			  opacity: .5;
			}
		}

		input[type="radio"] {
		  height: 1px;
		  width: 1px;
		  opacity: 0;

			&:checked + label:after {
			  -webkit-transform: scale(1);
			  transform: scale(1);
			  opacity: 1;
			}

			&:checked + label:before {
			  // border: 1px solid $navy;
			}

			&:focus + label:after {
			  -webkit-transform: scale(1);
			  transform: scale(1);
			  opacity: 1;
			  background-color: #e67012;
			}
		}

		label:before {
			content: '';
		  height: 26px;
		  width: 26px;
		  top: 0;
			left: 0;
		  display: block;
		  cursor: pointer;
		  float: left;
		  margin: 0 9px 0 10px;
		  border: 1px solid $navy;
		  border-radius: 50%;
		  background-color: transparent;
		  position: absolute;
		}

		label:after {
			content: '';
			top: 0;
			left: 0;
		  -webkit-transition: all 0.25s ease-in-out;
		  transition: all 0.25s ease-in-out;
		  height: 16px;
		  width: 16px;
		  -webkit-transform: scale(0);
		  transform: scale(0);
		  display: block;
		  margin: 5px 9px 5px 15px;
		  border-radius: 50%;
		  background-color: #f08b3b;
		  opacity: 1;
		  position: absolute;
		}

	}

}



/*
 * Checkboxes
 */

.gfield_checkbox {

	margin-bottom: 0;

	li {
	  display: inline-block;
	  padding-right: 20px;
	  font-size: 18px;
	  @include line-height(26px);
	  cursor: pointer;
	  position: relative;

	  > label {
	  	display: inline-block;
			padding-left: 32px;
			@include font-size(18px);
			@include line-height(26px);
	  }

		&:hover {
			.inner {
			  -webkit-transform: scale(0.5);
			  transform: scale(0.5);
			  opacity: .5;
			}
		}

		input[type="checkbox"] {
		  height: 1px;
		  width: 1px;
		  opacity: 0;
	    position: absolute;

			&:checked + label:after {
			  -webkit-transform: scale(1);
			  transform: scale(1);
			  opacity: 1;
			}

			&:checked + label:before {
			  // border: 2px solid $navy;
			}

			&:focus + label:after {
			  -webkit-transform: scale(1);
			  transform: scale(1);
			  opacity: 1;
			  background-color: #e67012;
			}

			&:not(:checked) + label:after {
			  -webkit-transform: scale(0);
			  transform: scale(0);
			  opacity: 0;
			  background-color: #e67012;
			}

		}

		label:before {
			content: '';
		  height: 26px;
		  width: 26px;
		  top: 0;
			left: 0;
		  display: block;
		  cursor: pointer;
		  float: left;
	    margin: 0 9px 0 0;
		  border: 1px solid $navy;
		  border-radius: 0;
		  background-color: transparent;
		  position: absolute;
		}

		label:after {
			content: '';
			top: 0;
			left: 0;
		  -webkit-transition: all 0.25s ease-in-out;
		  transition: all 0.25s ease-in-out;
		  height: 16px;
		  width: 16px;
		  -webkit-transform: scale(0);
		  transform: scale(0);
		  display: block;
		  margin: 5px 9px 5px 5px;
		  border-radius: 0;
		  background-color: #f08b3b;
		  opacity: 1;
		  position: absolute;
		}

	}

}

/*
 * Address Field
 */

.address_line_1 {
  width: 48%;
	margin-right: 4%;
	display: inline-block;

	@include media($mobile-down) {
		width: 100%;
		margin-right: 0;
	}

}

.address_line_2 {
	width: 48%;
	margin-right: 0;
	display: inline-block;

	@include media($mobile-down) {
		width: 100%;
		margin-right: 0;
	}

}

.address_city {
	width: 48%;
	margin-right: 4%;
	display: inline-block;

	@include media($mobile-down) {
		width: 100%;
		margin-right: 0;
	}

}

.address_state {
	width: 48%;
	margin-right: 0;
	display: inline-block;

	@include media($mobile-down) {
		width: 100%;
		margin-right: 0;
	}

}

.address_zip {
	width: 48%;
	margin-right: 4%;
	display: inline-block;

	@include media($mobile-down) {
		width: 100%;
		margin-right: 0;
	}

}

.address_country {
	width: 48%;
	margin-right: 0;
	display: inline-block;

	@include media($mobile-down) {
		width: 100%;
		margin-right: 0;
	}

	select {
	  width: 100%;
	}
}


/*
 * Credit Card Field
 */

.ginput_container_creditcard {

	input {

		&[type="text"] {
			width: auto;
		  float: none;
		  // min-width: 330px;
		  // max-width: 330px;
			width: 100%;
			min-width: 100%;
		  clear: both;
		  margin: 0 auto;
		  margin-bottom: 20px;
		  display: block;
		}

		&.ginput_card_security_code[type="text"] {
			width: 50%;
			min-width: 120px;
			float: left;
		}

	}

	.ginput_card_expiration_container.ginput_card_field {
		display: block;
		margin-bottom: 20px;
	}

}

.ginput_container_creditcard {
	max-width: 560px;
	margin: 0 auto;

		label {
			float: none;
			display: block;
			text-align: left;

			@include media($mobile-down) {
				clear: both;
			}
		}
}

.ginput_card_expiration_month, .ginput_card_expiration_year {
	float: left;
  display: inline-block;
  width: 47%;
  margin-right: 2%;
  clear: none;

	@include media($mobile-down) {
		margin-left: 0;
	}
}


.gform_card_icon_container {
	padding-bottom: 10px;
	text-align: left;

	.gform_card_icon {
		margin: 0 auto;
		text-indent: -9000px;
		background-repeat: no-repeat;
		width: 36px;
		height: 32px;
		float: none;
		display: inline-block;
		max-width: 25%;
		background-image: url(images/gf-creditcard-icons.png);
	}

	.gform_card_icon_amex {
		background-position: -72px 0;
	}

	.gform_card_icon_discover {
	  background-position: -108px 0;
	}

	.gform_card_icon_mastercard {
	  background-position: -36px 0;
	}

	.gform_card_icon_visa {
		background-position: 0 0;
	}

}

.ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
	margin: 0 auto;
	text-indent: -9000px;
	top: 9px;
	left: 6px;
	width: 36px;
	height: 32px;
	float: left;
	display: inline-block;
	max-width: 25%;
	background-image: url(images/gf-creditcard-icons.png);
  background-repeat: no-repeat;
  background-position: 0 -128px;
  position: relative;
  display: -moz-inline-stack;
  display: block;
}

// Layout changes
span#input_1_35_2_container {
  float: left;
  display: block;
	width: 100%;
}

span#input_1_35_2_cardinfo_left {
	display: block;
  float: left;
  width: 50%;
  float: left;

	@include media($mobile-down) {
		width: 100%;
		float: none;
	  margin-bottom: 65px;
	}
}

span#input_1_35_2_cardinfo_right {
  display: block;
  width: 29%;
  float: right;

	@include media($mobile-down) {
		width: 100%;
		float: none;
	}
}

// --------------------------------------------------------------------------
//   Search form
// --------------------------------------------------------------------------

.search-section-404 {
	.search-inner {
		padding: 20px 0;
		border-bottom: none;
		background: none;
	}
	.search-form {

		> label {
			position: relative;
			display: block;
			margin: 0 auto;
			width: 60%;
			margin-bottom: $base-margin;

			@include media($mobile-down) {
				width: 90%;
			}

			.search-field {
				padding: 1.2rem;
			}
		}

		.search-submit {
			width: 40%;
			margin: 0 auto;
			display: block;
		}
	}
}

.search-inner {
	@include outer-container;
	padding: $section-padding;
	border-bottom: $border-bottom;
	background-color: $gray;
}

.search-form {

	> label {
		position: relative;
		display: block;
		margin: 0 auto;
		width: 40%;
		margin-bottom: $base-margin;

		.search-field {
			padding: 1.2rem;
		}
	}

	.search-submit {
		width: 40%;
		margin: 0 auto;
		display: block;
	}
}

.gform_ajax_spinner {
	position: absolute;
}

// Misc

.gform_ajax_spinner {
	text-align: center;
}

img.gform_ajax_spinner {
	position: relative;
	clear: both;
	margin: 0 auto;
	display: block;
	padding: 15px;
}
