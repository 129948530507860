// ==========================================================================
//
//  Shame
//    Bad practice, browser hacks and quick fixes
//		Keep definitions in here for a to-do list to re-factor / replace later
//
// ==========================================================================


li#field_1_34 td {
	background-color: #EEEEEE !important;
	border-bottom: none !important;
	border-right: none !important;
	color: $navy;
	font-family: $gotham !important;
	font-style: normal;
	font-weight: 500;

		strong {
			color: $navy !important;
			font-family: $gotham !important;
			font-style: normal;
			font-weight: 500;
		}
}

li#field_1_34 table table table {
	border-top: 1px solid $purple !important;
	border-left: 1px solid $purple !important;
	border-right: 1px solid $purple !important;
	border-bottom: 1px solid $purple !important;
}

li#field_1_34 table table table tbody {
	border-top: 1px solid $purple !important;
}

li#field_1_34 td table tr th {
	background-color: #EEEEEE !important;
  border-bottom: none !important;
  border-right: none !important;
	color: $purple;
	font-family: $gotham !important;
	font-style: normal;
	font-weight: 300;
	text-transform: uppercase;
}
