// ======-====================================================================
//
//	Home Page
//
// ==========================================================================

// --------------------------------------------------------------------------
//   Home specific styles
// --------------------------------------------------------------------------

.home {

	.nav {

		@include media($tablet-down) {
			top: 140px;
		}
	}

}

// --------------------------------------------------------------------------
//   Hero Slider
// --------------------------------------------------------------------------

.hero-slider-wrapper {
	// min-height: 600px;
}


.hero-slider {
	z-index: 4;

	.slick-slide.hero-slider-slide {
		position: relative;
		padding: 0 0 110px;
		background-size: cover;
		background-position: center;

		@include media($tablet-up) {
			min-height: 700px;
		}

		@include media($tablet-down) {
			min-height: 600px;
		}

		@include media($mobile-down) {
			min-height: 600px;
			padding-bottom: 0;
		}
	}


	.slick-dots {
		bottom: 0;
	}
}


.hero-slider-headline {
	position: absolute;
	top: 16%;
	left: 0;
	width: 100%;
	z-index: 5;
	text-align: center;
	font-size: 61px;
	font-weight: 900;
	text-transform: uppercase;
	line-height: 2;
	color: $white;
	pointer-events: none;

	@include media($desktop-down) {
		font-size: 50px;
	}

	@include media($mobile-down) {
		top: 9vw;
		font-size: 7vw;
	}

}

.hero-slide-button {
	position: absolute;
	bottom: 130px;
	left: 50%;
	transform: translateX(-50%);

	@include media($mobile-down) {
		bottom: 55px;
	}
}


// --------------------------------------------------------------------------
//   Welcome Section
// --------------------------------------------------------------------------

.welcome {
	padding-top: 37.5px; // 75px base padding + 40px to offset top margin

	&:before {
		content: " ";
		display: block;
		position: absolute;
		background: $purple;
		width: 50%;
		right: 0;
		top: 0;
		height: 100%;
		z-index: 0;

			@include media($mobile-down) {
				content: none;
				display: none;
			}
	}

	@include media($mobile-down) {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;

		&:before {
			content: none;
			display: none;
		}
	}
}

.welcome-left {
	@include outer-container;
	padding: $section-padding;
	text-align: center;
	color: $white;
	max-width: 50%;
	width: 50%;

	@include media($mobile-down) {
		max-width: 100%;
		padding: $section-padding-sm;
	}
}

.welcome-left-inner {
	@include span-columns(12);
	margin: 0 auto;
	float:right;
	max-width: $max-width / 2;

	@include media($mobile-down) {
		max-width: 100%;
	}
}

.welcome-left-border-box {
	max-width: 350px;
	position: relative;
	padding-top: 60px;
	padding-bottom: 60px;
	clear: both;
	margin: 0 auto;
	text-transform: uppercase;
	float: none;

	@include media($tablet-down) {
		width: auto;
	}
}

.welcome-title {
	color: $white;
	display: block;
	font-family: $gotham;
	font-style: normal;
	font-weight: 500;
	text-transform: capitalize;
	@include font-size(30px);
}

.welcome-right {
	@include outer-container;
	padding: $section-padding;
	background-color: $purple;
	text-align: center;
	color: $white;
	max-width: 50%;
	width: 50%;

	@include media($mobile-down) {
		max-width: 100%;
	}
}

.welcome-quote {
	@include span-columns(12);
	margin: 0 auto;

	max-width: $max-width / 2;
	@include media($mobile-down) {
		@include span-columns(12);
		max-width: 100%;
	}
}

.welcome-quote-inner {
	font-family: $gotham;
	font-style: normal;
	font-weight: 500;
	max-width: 300px;
	position: relative;
	padding-top: 65px;
	padding-bottom: 65px;
	clear: both;
	margin: 0 auto;
	@include font-size(20px);
	@include line-height(32px);
	text-transform: uppercase;
	float: none;

	&:before {
		content: " ";
		top: 0;
		height: 40px;
		width: 40px;
		left: 0;
		right: 0;
		margin-right:auto;
		margin-left: auto;
		display: block;
		position: absolute;
		background-image: url(images/icon-open-quote.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100%;

	}

	&:after {
		content: " ";
		bottom: 0;
		height: 40px;
		width: 40px;
		left: 0;
		right: 0;
    margin-right:auto;
    margin-left: auto;
		display: block;
		position: absolute;
		background-image: url(images/icon-open-quote.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: 100%;

	}
}

// --------------------------------------------------------------------------
//   Home Panel Section
// --------------------------------------------------------------------------

.section-home-panel {
	padding: $section-padding-sm;
	padding-top: 0;

	&:before {
		content: " ";
		display: block;
		position: absolute;
		background: $purple;
		width: 50%;
		right: 0;
		top: 0;
		height: 100%;
		z-index: 0;

		@include media($mobile-down) {
			content: none;
			display: none;
		}
	}
}

// --------------------------------------------------------------------------
//   Donate Section
// --------------------------------------------------------------------------

.section-donate {
	padding: $section-padding;
	background-image: url(images/bg-section-donate.jpg);
	background-position: 100% top;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: $white;
	border-bottom: $border-bottom;

	@include media($desktop-down) {
		background-position: 50% top;
	}

}

.donate-panel {
	text-align: right;
	float: right;

	@include media($tablet-down) {
		@include span-columns(12);
		@include shift(0);
		text-align: center;
		// manually clear the shift() mixin
		&:last-child {
			margin-right: auto;
			margin-left: auto;
			float: none;
		}

	}

	@include media($mobile-down) {
		text-align: center;
		float: none;
	}
}

// --------------------------------------------------------------------------
//   Team Section
// --------------------------------------------------------------------------

.section-team {
	padding: $section-padding;
	padding-bottom: 37.5px;

	@include media($mobile-down) {
		padding: $section-padding-sm;
	}
}

.section-team-button {
	text-align: center;
	padding: $section-padding-sm;
}
