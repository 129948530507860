// ==========================================================================
//
//  Layout
//    Primary stylesheet
//
// ==========================================================================


html {
	background-color: $white;
	overflow-x: hidden;
}

html.no-touch {
	overflow-y: auto;
}

body {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $gray;
	@include clearfix;

	&.admin-bar {
		padding-top: 32px;

		@include media($mobile-down) {
			padding-top: 46px;
		}
	}

	&.blog,
	&.not-home {
		background-color: $white;
	}
}

#page-wrapper {
	position: relative;
	width: 100%;
}

@mixin center {
	*zoom: 1;
	margin-left: auto;
	margin-right: auto;
	@include clearfix;

	width: $large-desktop;

	@include media($desktop-down) {
		width: $desktop;
	}

	@include media($tablet-down) {
		width: $tablet;
	}

	@include media($mobile-down) {
		max-width: $mobile;
		width: auto;
	}
}


// --------------------------------------------------------------------------
//   Sections
// --------------------------------------------------------------------------

.wrapper {
	display: block;
	position: relative;
	padding: $gap * 2 $gap;

	.section {
		// @include center;

		.section-content {
			@include row;
		}
	}

	@include media($mobile-down) {
		padding: 0;
	}
}

// --------------------------------------------------------------------------
//   Header
// --------------------------------------------------------------------------

#header-wrapper {
	padding-top: $gap * 2;
	padding-bottom: $gap * 2;
	z-index: 99;

	#header {

		#header-content {

		}
	}
}

#logo {
	@include span-columns(4);

	img + h1 {
		@include visuallyhidden;
	}
}


// --------------------------------------------------------------------------
//   Banner
// --------------------------------------------------------------------------

#banner-wrapper {

	#banner {

		#banner-content {
			position: relative;

			#hero {
				position: relative;
				z-index: 2;
			}
		}
	}
}


// --------------------------------------------------------------------------
//   Main Content
// --------------------------------------------------------------------------

#main-wrapper {

	#main {
		padding: $gap 0;

		#main-content {

		}
	}
}


// --------------------------------------------------------------------------
//   Content
// --------------------------------------------------------------------------

#content {
	@include span-columns(12);

	.archive-title {
		border-bottom: 1px solid $base-border-color;
		margin-bottom: $gap;
		padding-bottom: 10px;
	}

	article.hentry {

		header {

			h3 {
				margin: 0;
			}

			time {
				margin-bottom: 0;
				font-size: 1em;
			}

			.meta {
				margin-bottom: $gap;
				margin-top: 0;
				font-size: 1em;
			}
		}

		.content {

		}

		footer {

		}
	}

	// ----- Mobile ----- //

	@include media($mobile-down) {
		@include span-columns(12);
	}
}

#content.full-width {
	@include span-columns(12);
}


// --------------------------------------------------------------------------
//   Archives
// --------------------------------------------------------------------------

.archive, .search, .blog {

	#content {

		article {
			border-bottom: 1px solid $base-border-color;
			margin: 0 0 $gap * 2;
		}
	}
}


// --------------------------------------------------------------------------
//   Comments
// --------------------------------------------------------------------------

.comment {
	$comment-gutter: $gap;
	$comment-image-width: 48px;
	$comment-color: $base-body-color;
	$comment-detail-color: $base-border-color;
	$comment-image-vert-alignment: top;

	display: table;
	width: 100%;
	margin-bottom: $gap;
	padding-bottom: 10px;
	border-bottom: 1px solid $base-border-color;

	.comment-image,
	.comment-content {
		display: table-cell;
		vertical-align: $comment-image-vert-alignment;
	}

	.comment-image {
		padding-right: $comment-gutter;

		> img {
			width: $comment-image-width;
			height: auto;
			border-radius: $base-border-radius;
			display: block;
			max-width: none;
		}

		.comment-reverse-order & {
			padding-right: 0;
			padding-left: 10px;
		}
	}

	.comment-content {
		width: 100%;

		p {
			margin-bottom: 10px;
		}

		.comment-detail {
			font-style: italic;
			font-size: 0.9em;
			color: $comment-detail-color;
		}
	}
}

.comment-form-comment {

	label {
		display: block;
	}

	#comment {
		width: 100%;
	}
}

// --------------------------------------------------------------------------
//   Sidebar
// --------------------------------------------------------------------------

#sidebar {
	@include span-columns(6);


	// ----- Generic widget ----- //

	.widget {
		margin-bottom: $gap;
		@include clearfix;

		.widget-title {
			margin-bottom: 10px;
		}

		ul {
			border-top: 1px solid $base-border-color;
			padding-left: 0;
			margin: 0;

			li {
				list-style: none;
				border-bottom: 1px solid $base-border-color;
				padding: 0;

				a {
					display: block;
					padding: 10px 5px;
				}
			}
		}

		.sub-menu li:last-child {
			border-bottom: none;
		}
	}

	// ----- Sidebar menu ----- //

	.widget_sub_menu {

		.menu {
			border-top: none !important;
			margin-left: 0;
		}

		.current_page_ancestor {

			border: none !important;

			> a {
				font-size: 1em;
			}

		}

		.active > a {
			color: inherit;
		}

		> .active {

			border: none !important;

			> a {
				font-size: 1em;
			}
		}
	}

	// ----- Mobile ----- //

	@include media($mobile-down) {
		@include span-columns(4);
	}
}


// --------------------------------------------------------------------------
//   Footer
// --------------------------------------------------------------------------

#footer-wrapper {


	#footer {

		#footer-content {

		}
	}
}

.footer-nav {
	@include span-columns(12);

	ul {
		padding: 0;
		margin: 0;
		display: inline-block;
		list-style: none;

		li {

		}
	}
}
