// ==========================================================================
//
//	Contact Page Styles
//
// ==========================================================================

.section-contact {
	background-color: $gray;
	padding: $section-padding;
}

.contact-content {
	
}

.contact-form-wrap {
	max-width: $paragraph;
	margin: 0 auto;
}

.contact-form-form {

}
