// ==========================================================================
//
//  Mixins
//		Place any prefix fixing or often used attribute bundles here
//
// ==========================================================================

// --------------------------------------------------------------------------
//   Opacity
// --------------------------------------------------------------------------

@mixin opacity($value){
	$IEValue: $value*100;
	opacity: $value;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$IEValue+")";
	filter: alpha(opacity=$IEValue);
}


// --------------------------------------------------------------------------
//   CSS Hyphens
// --------------------------------------------------------------------------

@mixin css-hyphens($val) {
	// Accepted values: [ none | manual | auto ]
	-webkit-hyphens: $val;	// Safari 5.1 thru 6, iOS 4.2 thru 6
	-moz-hyphens: $val;		// Firefox 16 thru 20
	-ms-hyphens: $val;		// IE10
	-o-hyphens: $val;		// PRESTO...haha LOL
	hyphens: $val;			// W3C standard
}


// --------------------------------------------------------------------------
//   Size an element
// --------------------------------------------------------------------------

@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

// --------------------------------------------------------------------------
//   Convert PX to REM
// --------------------------------------------------------------------------

// Divide input by root element font-size to get REM value
@function font-size-converter($font-size) {
  $rem-font-size: $font-size / 18px;
  @return #{$rem-font-size}rem;
}

@mixin font-size($font-size) {
  font-size: $font-size ; //Fallback in px
  font-size: font-size-converter($font-size);
}

// Line height px to REM
@function line-height-converter($line-height) {
  $rem-line-height: $line-height / 18px;
  @return #{$rem-line-height}rem;
}

@mixin line-height($line-height) {
  line-height: $line-height ; //Fallback in px
  line-height: line-height-converter($line-height);
}

// --------------------------------------------------------------------------
//		Reset the omega Mixin in Bourbon Neat automatic rows
// --------------------------------------------------------------------------
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}
