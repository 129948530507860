// ==========================================================================
//
//  Modals
//
//
// ==========================================================================

.modal {
  $medium-screen: 30em !default;
  $large-screen: 50em !default;

  label {
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0;
  }

  .modal-state {
    display: none;
  }

  .modal-fade-screen { // overlay
    @include position(fixed, 0 0 0 0);
    background-color: rgba(0, 0, 0, 0.85);
    opacity: 0;
		display: none;
    transition: opacity 0.25s ease;
    visibility: hidden;
    z-index: 999999;

    @include media($large-screen) {
      padding-top: 10em;
    }
  }

  .modal-bg {
    @include position(absolute, 0 0 0 0);
    cursor: pointer;
  }

  .modal-close {
    cursor: pointer;
		text-align: center;
  }

  .modal-inner {
    background-color: #fff;
    margin: auto;
    max-height: 95%;
		max-width: 1000px;
    overflow: auto;
    position: relative;
    top: 0.5em;
    padding: 30px 50px;
    transition: opacity 0.25s ease;
    width: 95%;

    @include media($medium-screen) {
      max-height: 70%;
      width: 60%;
			min-height: 400px;
    }

    @include media($large-screen) {
      width: 50%;
    }
  }

  .modal-state:checked + .modal-fade-screen {
    opacity: 1;
    visibility: visible;
		display: block;
  }
}

.modal-open {
  overflow: hidden;
}

.modal-closed {
  overflow: auto;
}
