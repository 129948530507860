// ==========================================================================
//
//  Type
//    Default typographic styles and elements
//
// ==========================================================================

html {
	font-size: 16px;

	@include media($mobile-down) {
		font-size: 14px;
	}

}

body {
	font-size: 16px;
	font-size-adjust: auto;
	line-height: 26px;
	text-align: left;
	font-family: $gotham;
	font-style: normal;
	font-weight: 300;
	background-color: $white;
	color: $base-body-color;
	word-wrap: break-word;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;

	&#tinymce {
		background-color: $white;
		padding: 10px !important;
	}

	@include media($mobile-down) {
		font-size: 14px;
	}

}


p {

}

strong {

}

em {

}

// --------------------------------------------------------------------------
//   Lists
// --------------------------------------------------------------------------

ul {
	font-family: $gotham;
	font-style: normal;
	font-weight: 300;
	@include font-size(16px);
	@include line-height(24px);
}

li, ol {

}

// --------------------------------------------------------------------------
//   Convert PX to REM
// --------------------------------------------------------------------------

// Divide input by root element font-size to get REM value
@function font-size-converter($font-size) {
  $rem-font-size: $font-size / 16px;
  @return #{$rem-font-size}rem;
}

@mixin font-size($font-size) {
  font-size: $font-size ; //Fallback in px
  font-size: font-size-converter($font-size);
}

// Line height px to REM
@function line-height-converter($line-height) {
  $rem-line-height: $line-height / 16px;
  @return #{$rem-line-height}rem;
}

@mixin line-height($line-height) {
  line-height: $line-height ; //Fallback in px
  line-height: line-height-converter($line-height);
}

// --------------------------------------------------------------------------
//   Headings
// --------------------------------------------------------------------------

h1, h2, h3, h4, h5, h6 {
	font-family: $gotham;
	color: $base-heading-color;
	font-style: normal;
	font-weight: 700;
	line-height: 1.2;

	&:first-child {
		margin-top: 0;
	}
}

h1 {
	@include font-size(36px);
	margin-top: 0;

	@include media($mobile-down) {
		@include font-size(24px);
	}
}

h2 {
	@include font-size(32px);

	@include media($mobile-down) {
		@include font-size(22px);
	}
}

h3 {
	@include font-size(24px);

	@include media($mobile-down) {
		@include font-size(20px);
	}
}

h4 {
	@include font-size(16px);

	@include media($mobile-down) {
		@include font-size(14px);
	}
}

h5 {
	@include font-size(16px);

	@include media($mobile-down) {
		@include font-size(14px);
	}
}

h6 {
	@include font-size(14px);
}

h1 + h2, h2 + h3, h3 + h4, h5 + h6 {
	margin-top: 0;
}

.entry-content, #tinymce {

	h1, h2, h3, h4, h5, h6 {
		color: $black;
	  -webkit-font-smoothing: subpixel-antialiased;
	}

	h1 {
		@include font-size(90px);
		margin-top: 0;

		@include media($mobile-down) {
			@include font-size(24px);
		}
	}

	h2 {
		@include font-size(36px);
		text-transform: uppercase;

		@include media($mobile-down) {
			@include font-size(22px);
		}
	}

	h3 {
		@include font-size(36px);
		font-family: $gotham;
		font-style: normal;
		font-weight: 300;
		text-transform: uppercase;
		-webkit-font-smoothing: antialiased;

		@include media($mobile-down) {
			@include font-size(22px);
		}
	}

	h4 {
		@include font-size(20px);
		text-transform: uppercase;

		@include media($mobile-down) {
			@include font-size(16px);
		}
	}

	h5 {
		@include font-size(18px);
		text-transform: uppercase;
		font-family: $gotham;
		font-style: normal;
		font-weight: 300;
		-webkit-font-smoothing: antialiased;

		@include media($mobile-down) {
			@include font-size(14px);
		}
	}

	h6 {
		@include font-size(14px);
		color: $dark-gray;
		font-family: $gotham;
		font-style: normal;
		font-weight: 300;
		text-transform: uppercase;
		-webkit-font-smoothing: antialiased;
	}

}

// --------------------------------------------------------------------------
//   Layout Elements
// --------------------------------------------------------------------------

a { color: $base-link-color ; text-decoration: none; }
a:hover, a:focus { color: $base-link-hover; }

p {
	margin-top: 0;
	margin-bottom: 20px;
}

figure {
	margin: 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

hr {
	display: block;
	margin: 20px 0;
	border: none;
	border-top: 1px solid #ccc;
	color: #222;
	background-color: #222;
}

blockquote {
	margin: 0 0 $gap 0;
	font-family: cambria, georgia, times, serif;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	background: #fafafa;
	padding: $gap;
	font-style: italic;
	p:last-child { margin-bottom: 0; }
}

ins {
	background: #ff9;
	color: #000;
	text-decoration: none;
}

mark {
	background: #ff0;
	color: #000;
	font-style: italic;
	font-weight: 700;
}

table {
	width: 100%;
}
