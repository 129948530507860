// --------------------------------------------------------------------------
//   Side Image Section
// --------------------------------------------------------------------------

.section-side-image {
	background-color: $white;
	padding: $section-padding;
	@include clearfix;
}

.slide-image-body {
	max-width: $paragraph;
}

// Left Side

.side-image-text {
	width: 100%;
	float: none;
	padding-left: 100px;
	padding-right: 100px;

	@include media($tablet-down) {
		padding-left: 20px;
		padding-right: 20px;
	}

	@include media($mobile-down) {
		width: 100%;
		padding: inherit;
		max-width: $paragraph;
		margin: 0 auto;
	}
}

.side-image-text-heading {
	font-weight: bold;
	@include font-size(18px);
	margin: auto;
	margin: 1rem auto;
}

.side-image-text-body {
	margin: auto;
}

// Right Side

.side-image-image {
	width: 33%;
	float: right;
  margin-left: 50px;

	@include media($tablet-down) {
	  margin-left: 20px;
	}

	@include media($mobile-down) {
		width: 100%;
		margin-left: 0;
		max-width: $paragraph;
		margin: 0 auto;
		text-align: center;
		float: none;
	}
}
