// ==========================================================================
//
//	Statistics Seciton
//
// ==========================================================================

.section-statistics {
	width: 100%;
	background-color: $purple;
	padding: 50px 0;
}

.statistics-content {
	white-space: nowrap;
	text-align: center;

}

.statistics-block {
	float: none;
	display: inline-block;
	box-sizing: border-box;
	height: auto;
	width: 50%;
	max-width: 450px;
  min-width: 450px;
	height: 300px;
	overflow: hidden;
	margin: 10px;
	padding: 27px 15px;
	clear: none;
	text-align: center;
	white-space: normal;
	vertical-align: top;
	border: 1px solid $white;

	@include media($tablet-down) {
		display: block;
		height: auto;
		margin: auto;
		margin-bottom: 40px;
	}

	@include media($mobile-down) {
		width: 100%;
		max-width: 450px;
		min-width: 50%;
	}
}

.statistic-block-heading,
.statistic-block-number,
.statistic-block-subheading,
.statistic-block-text {
	color: $white;
	text-transform: uppercase;
	font-family: $gotham;
	font-style: normal;
	font-weight: 300;
}

.statistic-block-heading {
	@include font-size(18px);
	font-weight: 300;
	-webkit-font-smoothing: antialiased;

}

.statistic-block-number {
	@include font-size(72px);
	margin-bottom: $base-margin;

	@include media($mobile-down) {
		@include font-size(42px);
	}
}

.statistic-block-subheading {
	@include font-size(28px);
	margin-bottom: 10px;

	@include media($mobile-down) {
		@include font-size(22px);
	}
}

.statistic-block-text {
	@include font-size(18px);
	margin-bottom: 0;
	line-height: 1.4em;
}
