// ==========================================================================
//
//	Sections
//
// ==========================================================================

.section {
	display: block;
	position: relative;
	@include clearfix;

	@include media($mobile-down) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

@mixin center {

	@include outer-container;

	@include media($desktop-down) {
		width: $desktop;
	}

	@include media($tablet-down) {
		width: $tablet;
	}

	@include media($mobile-down) {
		max-width: 100%;
		width: auto;
	}
}

.section-heading {
	display: block;
	position: relative;
	width: 100%;
	text-align: center;
	padding-bottom: 37.5px;
	text-transform: uppercase;
	font-weight: normal;
	@include font-size(30px);
	color: $purple;
	font-family: $gotham;
	font-style: normal;
	font-weight: 700;
}

.section-content {
	@include clearfix;
	@include center;
	position: relative;

	@include media($mobile-down) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.center {
	@include center;
	@include clearfix;
	position: relative;
}

// --------------------------------------------------------------------------
//   Full section
// --------------------------------------------------------------------------

.section-full {
	@include span-columns(12);

	@include media($mobile-down) {
		margin-bottom: $base-margin;
	}
}

// --------------------------------------------------------------------------
//   Half sections
// --------------------------------------------------------------------------

.section-half {
	position: relative;
	@include span-columns(6);

	@include media($mobile-down) {
		width: 100%;
		float: none;
		margin-bottom: $base-margin;

	}
}

// --------------------------------------------------------------------------
//   Quarter Section
// --------------------------------------------------------------------------

.quarter-section {
	@include span-columns(3);

	@include media($desktop-down) {
		@include span-columns(6);
		@include omega(2n);
	}

	@include media($mobile-down) {
		@include span-columns(6);
		@include media($mobile-down) {
			margin-bottom: $base-margin;
		}
	}
}

// --------------------------------------------------------------------------
//   Variations
// --------------------------------------------------------------------------
.section-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
