// // ==========================================================================
// //
// //	Navigation
// //
// // ==========================================================================

// // --------------------------------------------------------------------------
// //   Main Navigation
// // --------------------------------------------------------------------------

$navigation-height: 180px;

.navigation {
  background-color: $white;
  min-height: $navigation-height;
  width: 100%;

  @include media($tablet-down) {
    min-height: 120px;
  }
}

.section-nav {
  @include clearfix;
  @include outer-container;
  position: relative;
  height: 180px;

  @include media($tablet-down) {
    height: 140px;
  }
}

.navigation-links {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-30%);
  background: transparent;
  clear: none;
  float: right;
  padding-right: 20px;
  z-index: 99999;

  @include media($tablet-down) {
    position: relative;
    top: 0;
    height: 140px;
    width: auto;
    float: none;
    padding: 0;
    padding-right: 4%;
    transform: none;
    position: relative;
    height: 140px;
    width: auto;
    float: none;
    transform: none;
  }
  @include media($tablet-up) {
    max-width: 70%;
  }
}

.nav-logo {
  float: left;
  max-height: $navigation-height;
  width: 30%;
  // max-width: 250px;
  padding-top: 20px;

  @include media($tablet-down) {
    max-width: 200px;
    padding-top: 15px;
    margin-left: 4%;
  }
}

.nav-logo-image {
  max-height: $navigation-height;
  padding: 0.8em 0;
  width: auto;
  height: auto;
  min-width: 200px;
}

// --------------------------------------------------------------------------
//   Main Navigation Mobile Menu
// --------------------------------------------------------------------------

.navigation-menu {

  @include media($tablet-down) {
    max-width: $tablet;
    margin: 0 auto;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 20px;

  }

  @include media($mobile-down) {
    width: 95%;
    margin: 20px auto;
  }
}

.navigation-menu-button {
  display: block;
  float: right;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  top: 50%;
  transform: translateY(-50%);


  @include media ($tablet-up) {
    display: none;
  }

  @include media($tablet-down) {
    padding: 15px;
  }

  &:focus,
  &:hover {
    // color: $orange;
  }
}

.nav {
  float: left;
  display: block;
  position: relative;
  width: auto;
  overflow: hidden;
  max-height: 0;
  background: $gray;

  &.active {
    @include media ($tablet-down) {
      max-height: 2000px;
      overflow: scroll;
      border-bottom: 4px solid $navy;
    }
  }

  > ul {

    > ul {
      clear: both;
      transition: all 0.3s ease-out;
    }

    ul {
      overflow: hidden;
      max-height: 0;
      background: $gray;

      &.active {
        max-height: 999px;
      }

    }
  }

  li {
    list-style: none;
    margin-bottom: 10px;

    &.menu-item-has-children {

      > a:after {
        content: '+';
        color: $purple;
        position: absolute;
        top: 0;
        right: 20px;
        width: 42px;
        height: 42px;
        line-height: 42px;
        z-index: 9999;
        display: block;
        font-size: 17px;
        padding: 0;
        text-align: center;
      }

      > a.active {

        @include media ($tablet-down) {
          color: $orange;

          &:after {
            content: "-";
            color: $orange;
          }

          .expand-menu {
            border-color: $orange;
          }
        }
      }
    }

    &:hover {
      > a {
        color: $orange;
      }
    }

      a {
        color: $navy;
        display: block;
        padding: 10px 10px;
        position: relative;
        font-family: $gotham;
        font-style: normal;
      	font-weight: 500;
        @include font-size(16px);
      }

  }

  // .nav media queries
  @include media ($tablet-down) {
    float: none;
    width: 100%;
    position: absolute;
    top: 141px;
    // max-width: $tablet; // option to make the mobile nav float in the center
    margin: 0 auto;
    left: 0;
    right: 0;
  }

}

.expand-menu {
  width: 42px;
  height: 42px;
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 99999;
  display: block;
  font-size: 17px;
  padding: 0;
  border: 1px solid $purple;
}

@include media($tablet-up) {

  .nav {
    max-height: none;
    overflow: visible;
    background: none;

    > ul {

      ul {
        max-height: none;
        overflow: visible;
        background: transparent;
        display: none;
        position: absolute;
        top: 0;
        min-width: 210px;
        max-width: 210px;

      }

      li {
        display: inline-block;
        position: relative;
        list-style: none;

        &:hover {

          > a {
            color: $orange;
          }

        }

        &:last-child {

          .sub-menu {

            @include media($tablet-up) {
              left: -50%; // prevent last sub-menu dropdown from overflowing the container
            }
          }
        }

      }

    }

    li {
      margin-bottom: 0;
      padding: 0px 16px;
      &.menu-item-has-children {

        ul {

          li {

            > a:after {
              right: 10px;
              font-weight: bold;
            }

          }

        }

        > a:after,
        > a.active:after {
          position: absolute;
          top: 0;
          bottom: auto;
          right: -7px;
          padding: 7px 0;
          content: '\25BE';
          color: $navy;
          width: auto;
          line-height: 26px;
        }
      }

        a {

        }
    }

    // First Level Dropdown
    .sub-menu {
      top: 3em;
      left: 0;
      padding: 0;

      // Second Level Dropdown
      .sub-menu {
        left: 100%;
        top: 0;
        min-width: 180px;

      }

      > li {
        position: relative;
        width: 100%;
        padding: 0;
        margin-bottom: 2px;
        background: $gray;

        a {
          font-family: $gotham;
          font-style: normal;
          font-weight: 300;
        }

        &.menu-item-has-children {

          > a:after {
            content: '›';
            font-size: 1.2em;
            position: absolute;

          }

        }

        &:hover,
        &:focus {
          background-color: $navy;

            > a {
              color: $white;

              &:after {
                color: $white;
              }
            }
        }

      }

    }

  }

  .expand-menu {
    display: none;
    opacity: 0;
  }

}
