// ==========================================================================
//
//	Header
//
// ==========================================================================

// --------------------------------------------------------------------------
// Top Donate Bar
// --------------------------------------------------------------------------

.top-bar {
	background-color: $orange;
	height: 25px;
	transition: $bg-transition;

	@include media($tablet-down) {
		height: 35px;

		&:hover {
			background-color: $navy;

			.button-donate {
				background-color: $navy;
				border-color: $navy;
			}
		}
	}
}

.top-bar-inner {
	position: relative;
	height: 25px;
	@include clearfix;
	@include outer-container;

	@include media($tablet-down) {
		height: 35px;
	}

	.button-donate {
		position: absolute;
		z-index: 999;
		bottom: -50px;
		right: 40px;
		color: $white;
		transition: $bg-transition, border 0.3s ease-in-out;

		@include media($tablet-down) {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			padding: 0;
			line-height: 35px;
			max-height: 35px;
			margin: 0 auto;
			width: 100%;
			max-width: 100%;
		}

	}
}
